import { mapHelper } from 'common/utils'

// 层级
const menuLevel = [
  {
    text: '顶级菜单',
    value: -1
  },
  {
    text: '一级菜单',
    value: 0
  },
  {
    text: '二级菜单',
    value: 1
  },
  {
    text: '三级菜单',
    value: 2
  },
  {
    text: '四级菜单',
    value: 3
  },
  {
    text: '五级菜单',
    value: 4
  }
]

const {
  map: menuLevelMap,
  setOps: setMenuLevelOps
} = mapHelper.setMap(menuLevel)

// 类型
const menuType = [
  {
    text: '文件夹',
    value: 1
  },
  {
    text: '页面',
    value: 2
  },
  {
    text: '按钮',
    value: 3
  }
]

const {
  map: menuTypeMap,
  setOps: setMenuTypeOps
} = mapHelper.setMap(menuType)

// 状态
const visible = [
  {
    text: '停用',
    value: 0
  },
  {
    text: '正常',
    value: 1
  }
]

const {
  map: visibleMap,
  setOps: setVisibleOps
} = mapHelper.setMap(visible)

// 角色归属
const allowUserType = [
  {
    disabled: false,
    label: '系统',
    text: '系统',
    value: '106'
  },
  {
    disabled: false,
    label: '平台',
    text: '平台',
    value: '100'
  },
  {
    disabled: false,
    label: '区域',
    text: '区域',
    value: '102'
  },
  {
    disabled: false,
    label: '项目',
    text: '项目',
    value: '101'
  },
  {
    disabled: false,
    label: '商家',
    text: '商家',
    value: '104'
  },
  {
    disabled: false,
    label: '门店',
    text: '门店',
    value: '105'
  },
  {
    disabled: false,
    label: '组织',
    text: '组织',
    value: '107'
  }
]

const {
  map: allowUserTypeMap,
  setOps: setAllowUserTypeOps
} = mapHelper.setMap(allowUserType)

// 打开方式
const urlJumpType = [
  {
    text: '后台嵌入',
    value: 0
  },
  {
    text: '页面重定向',
    value: 1
  },
  {
    text: '新窗口',
    value: 2
  }
]

const {
  map: urlJumpTypeMap,
  setOps: setUrlJumpTypeOps
} = mapHelper.setMap(urlJumpType)

// 授权方式
const authType = [
  {
    text: '原生',
    value: 0
  },
  {
    text: '外链非授权',
    value: 1
  },
  {
    text: '外链需授权',
    value: 2
  }
]

const {
  map: authTypeMap,
  setOps: setAuthTypeOps
} = mapHelper.setMap(authType)

// 按钮类型
const roleType = [
  {
    text: '可见',
    value: 1
  },
  {
    text: '新增',
    value: 2
  },
  {
    text: '修改',
    value: 3
  },
  {
    text: '导出',
    value: 4
  },
  {
    text: '其他',
    value: 5
  }
]

const {
  map: roleTypeMap,
  setOps: setRoleTypeOps
} = mapHelper.setMap(roleType)

// 使用终端类型（平台类型）
const menuScope = [
  {
    text: 'web端',
    value: 0
  },
  {
    text: '移动管理端',
    value: 1
  }
]

const {
  map: menuScopeMap,
  setOps: menuScopeOps
} = mapHelper.setMap(menuScope)

export {
  menuLevelMap,
  setMenuLevelOps,
  menuTypeMap,
  setMenuTypeOps,
  visibleMap,
  setVisibleOps,
  allowUserTypeMap,
  setAllowUserTypeOps,
  urlJumpTypeMap,
  setUrlJumpTypeOps,
  authTypeMap,
  setAuthTypeOps,
  roleTypeMap,
  setRoleTypeOps,
  menuScopeMap,
  menuScopeOps
}

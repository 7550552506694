var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "MenuForm" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { form: _vm.form, submitBefore: _vm.submitBefore },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "菜单名称",
                        rules: [
                          {
                            required: true,
                            message: "请输入菜单名称",
                            trigger: "change",
                          },
                        ],
                        prop: "menuName",
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: {
                          type: "textareaHor",
                          placeholder: "请输入菜单名称",
                          maxlength: 10,
                          width: _vm.width,
                        },
                        model: {
                          value: _vm.form.menuName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "menuName", $$v)
                          },
                          expression: "form.menuName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.idVisible
                    ? _c("el-form-item", { attrs: { label: "菜单ID" } }, [
                        _c("span", {
                          domProps: { textContent: _vm._s(_vm.form.id) },
                        }),
                      ])
                    : _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "菜单ID",
                            rules: [
                              {
                                required: true,
                                message: "请输入菜单ID",
                                trigger: "blur",
                              },
                            ],
                            prop: "id",
                          },
                        },
                        [
                          _c("v-input-number", {
                            attrs: {
                              placeholder: "请输入菜单ID",
                              controls: "",
                              "controls-position": "right",
                              max: 99999999999,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.id,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "id", $$v)
                              },
                              expression: "form.id",
                            },
                          }),
                          _c("v-button", {
                            staticClass: "tips",
                            attrs: { type: "text", text: "点击获取" },
                            on: { click: _vm.queryNewMenuId },
                          }),
                        ],
                        1
                      ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isAdd && _vm.showPlatformType,
                          expression: "isAdd && showPlatformType",
                        },
                      ],
                      attrs: {
                        label: "使用终端",
                        prop: "menuScope",
                        rules: [
                          {
                            required: true,
                            message: "请选择使用终端",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.menuScopeOps, width: _vm.width },
                        model: {
                          value: _vm.form.menuScope,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "menuScope", $$v)
                          },
                          expression: "form.menuScope",
                        },
                      }),
                      _c("span", { staticClass: "tips" }, [
                        _vm._v(
                          "注：父子层级的所属终端，请尽量保持一致，否则将造成菜单无法正常显示的情况"
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isAdd && _vm.showPlatformType,
                          expression: "!isAdd && showPlatformType",
                        },
                      ],
                      attrs: { label: "使用终端" },
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.menuScopeMap[_vm.form.menuScope])),
                      ]),
                    ]
                  ),
                  _vm.menuLevelVisible
                    ? _c("el-form-item", { attrs: { label: "层级" } }, [
                        _c("span", {
                          domProps: {
                            textContent: _vm._s(
                              _vm.menuLevelMap[_vm.form.menuLevel]
                            ),
                          },
                        }),
                      ])
                    : _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "层级",
                            rules: [
                              {
                                required: true,
                                message: "请选择层级",
                                trigger: "change",
                              },
                            ],
                            prop: "menuLevel",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.menuLevelOps,
                              width: _vm.width,
                            },
                            on: { change: _vm.menuLevelChange },
                            model: {
                              value: _vm.form.menuLevel,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "menuLevel", $$v)
                              },
                              expression: "form.menuLevel",
                            },
                          }),
                        ],
                        1
                      ),
                  _vm.iconSize
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "图标",
                            rules: [
                              {
                                required: true,
                                message: "请上传图标",
                                trigger: "change",
                              },
                            ],
                            prop: "iconUrl",
                          },
                        },
                        [
                          _c("v-uploader", {
                            attrs: {
                              action: _vm.uploadURL,
                              imgUrls: _vm.form.iconUrl,
                            },
                            on: {
                              "update:imgUrls": function ($event) {
                                return _vm.$set(_vm.form, "iconUrl", $event)
                              },
                              "update:img-urls": function ($event) {
                                return _vm.$set(_vm.form, "iconUrl", $event)
                              },
                            },
                          }),
                          _c("span", { staticClass: "tips" }, [
                            _vm._v(
                              _vm._s("注：上传" + _vm.iconSize + "显示最佳")
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.iconVisible
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "图标" } },
                        [
                          _c("v-uploader", {
                            attrs: {
                              action: _vm.uploadURL,
                              imgUrls: _vm.form.iconUrl,
                            },
                            on: {
                              "update:imgUrls": function ($event) {
                                return _vm.$set(_vm.form, "iconUrl", $event)
                              },
                              "update:img-urls": function ($event) {
                                return _vm.$set(_vm.form, "iconUrl", $event)
                              },
                            },
                          }),
                          _c("span", { staticClass: "tips" }, [
                            _vm._v("注：上传22*22显示最佳"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.typeVisible && _vm.typeNameVisible
                    ? _c("el-form-item", { attrs: { label: "所属平台" } }, [
                        _c("span", {
                          domProps: {
                            textContent: _vm._s(_vm.subPlatformName),
                          },
                        }),
                      ])
                    : _vm._e(),
                  _vm.typeVisible && !_vm.typeNameVisible
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "所属平台",
                            rules: [
                              {
                                required: true,
                                message: "请选择所属平台",
                                trigger: "change",
                              },
                            ],
                            prop: "type",
                          },
                        },
                        [
                          _c(
                            "v-select2",
                            _vm._b(
                              {
                                key: "select2Type",
                                attrs: {
                                  placeholder: "查询所属平台",
                                  width: _vm.width,
                                },
                                model: {
                                  value: _vm.form.type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "type", $$v)
                                  },
                                  expression: "form.type",
                                },
                              },
                              "v-select2",
                              _vm.typeParams,
                              false
                            )
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.parentMenuVisible && _vm.parentMenuNameVisible
                    ? _c("el-form-item", { attrs: { label: "上级菜单" } }, [
                        _c("span", {
                          domProps: { textContent: _vm._s(_vm.parentName) },
                        }),
                      ])
                    : _vm._e(),
                  _vm.parentMenuVisible && !_vm.parentMenuNameVisible
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "上级菜单",
                            rules: [
                              {
                                required: true,
                                message: "请选择上级菜单",
                                trigger: "change",
                              },
                            ],
                            prop: "parentId",
                          },
                        },
                        [
                          _c(
                            "v-select2",
                            _vm._b(
                              {
                                key: "select2ParentId",
                                ref: "parentMenu",
                                attrs: {
                                  subjoin: _vm.parentMenuSubjoin,
                                  placeholder: "查询上级菜单",
                                  width: _vm.width,
                                },
                                on: { onChange: _vm.parentMenuChange },
                                model: {
                                  value: _vm.form.parentId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "parentId", $$v)
                                  },
                                  expression: "form.parentId",
                                },
                              },
                              "v-select2",
                              _vm.parentMenuParams,
                              false
                            )
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.menuTypeVisible
                    ? _c("el-form-item", { attrs: { label: "类型" } }, [
                        _c("span", {
                          domProps: {
                            textContent: _vm._s(
                              _vm.menuTypeMap[_vm.form.menuType]
                            ),
                          },
                        }),
                      ])
                    : _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "类型",
                            rules: [
                              {
                                required: true,
                                message: "请选择类型",
                                trigger: "change",
                              },
                            ],
                            prop: "menuType",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.menuTypeOps,
                              width: _vm.width,
                            },
                            on: { change: _vm.menuTypeChange },
                            model: {
                              value: _vm.form.menuType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "menuType", $$v)
                              },
                              expression: "form.menuType",
                            },
                          }),
                        ],
                        1
                      ),
                  _vm.menuUrlVisible
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "菜单URL",
                            rules: [
                              {
                                required: true,
                                message: "请输入菜单URL",
                                trigger: "change",
                              },
                            ],
                            prop: "menuUrl",
                          },
                        },
                        [
                          _c("v-input", {
                            attrs: {
                              type: "textareaHor",
                              placeholder: "请输入菜单URL",
                              maxlength: 5000,
                              width: _vm.lwidth,
                            },
                            model: {
                              value: _vm.form.menuUrl,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "menuUrl", $$v)
                              },
                              expression: "form.menuUrl",
                            },
                          }),
                          _vm.form.authType !== 0
                            ? _c("span", { staticClass: "tips" }, [
                                _vm._v(
                                  "注：为保障平台数据安全，请配置“HTTPS”协议的URL"
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.visible
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "打开方式",
                            rules: [
                              {
                                required: true,
                                message: "请选择打开方式",
                                trigger: "change",
                              },
                            ],
                            prop: "urlJumpType",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.urlJumpTypeOps,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.urlJumpType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "urlJumpType", $$v)
                              },
                              expression: "form.urlJumpType",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.visible
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "授权方式",
                            rules: [
                              {
                                required: true,
                                message: "请选择授权方式",
                                trigger: "change",
                              },
                            ],
                            prop: "authType",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.authTypeOps,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.authType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "authType", $$v)
                              },
                              expression: "form.authType",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.menuUrl === "sharePage"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "表单URL" } },
                        [
                          _c("v-input", {
                            attrs: {
                              type: "textareaHor",
                              placeholder: "请输入表单文档URL",
                              maxlength: 5000,
                              width: _vm.lwidth,
                            },
                            model: {
                              value: _vm.form.reportUrl,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "reportUrl", $$v)
                              },
                              expression: "form.reportUrl",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.showHelpUrl
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "帮助文档URL" } },
                        [
                          _c("v-input", {
                            attrs: {
                              type: "textareaHor",
                              placeholder: "请输入帮助文档URL",
                              maxlength: 5000,
                              width: _vm.lwidth,
                            },
                            model: {
                              value: _vm.form.helpUrl,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "helpUrl", $$v)
                              },
                              expression: "form.helpUrl",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.roleTypeVisible
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "按钮类型",
                            rules: [
                              {
                                required: true,
                                message: "请选择按钮类型",
                                trigger: "change",
                              },
                            ],
                            prop: "roleType",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.roleTypeOps,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.roleType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "roleType", $$v)
                              },
                              expression: "form.roleType",
                            },
                          }),
                          _c("span", { staticClass: "tips" }, [
                            _vm._v("影响角色管理权限的显示位置（列）"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.menuCodeVisible
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "按钮code",
                            rules: [
                              {
                                required: true,
                                message: "请输入按钮code",
                                trigger: "change",
                              },
                            ],
                            prop: "menuCode",
                          },
                        },
                        [
                          _c("v-input", {
                            attrs: {
                              type: "textareaHor",
                              placeholder: "请输入按钮code",
                              maxlength: 20,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.menuCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "menuCode", $$v)
                              },
                              expression: "form.menuCode",
                            },
                          }),
                          _c("span", { staticClass: "tips" }, [
                            _vm._v("影响按钮实际操作效果"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.allowUserTypeVisible
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "角色归属",
                            rules: [
                              {
                                required: true,
                                message: "请选择角色归属",
                                trigger: "change",
                              },
                            ],
                            prop: "allowUserType",
                          },
                        },
                        [
                          _c("checkbox-plus", {
                            attrs: {
                              type: "default",
                              options: _vm.allowUserTypeOps,
                              value: _vm.form.allowUserType,
                              mode: "checkbox-plus",
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.form,
                                  "allowUserType",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "菜单排序",
                        rules: [
                          {
                            required: true,
                            message: "请输入菜单排序",
                            trigger: "blur",
                          },
                        ],
                        prop: "menuSort",
                      },
                    },
                    [
                      _c("v-input-number", {
                        attrs: {
                          placeholder: "请输入菜单排序",
                          controls: "",
                          "controls-position": "right",
                          max: 99,
                          width: _vm.width,
                        },
                        model: {
                          value: _vm.form.menuSort,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "menuSort", $$v)
                          },
                          expression: "form.menuSort",
                        },
                      }),
                      _c("span", { staticClass: "tips" }, [
                        _vm._v("建议设置为1、2"),
                      ]),
                    ],
                    1
                  ),
                  _vm.showSpecialNo && !_vm.editSpecialNo
                    ? _c("el-form-item", { attrs: { label: "菜单特殊编码" } }, [
                        _c("span", {
                          domProps: {
                            textContent: _vm._s(_vm.detailItem.specialNo),
                          },
                        }),
                      ])
                    : _vm._e(),
                  _vm.showSpecialNo && _vm.editSpecialNo
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "菜单特殊编码",
                            prop: _vm.needSpecialNo ? `specialNo` : undefined,
                            rules: _vm.needSpecialNo
                              ? [
                                  {
                                    required: true,
                                    message: "请输入菜单特殊编码",
                                    trigger: "blur",
                                  },
                                ]
                              : undefined,
                          },
                        },
                        [
                          _c("v-input", {
                            attrs: {
                              type: "textareaHor",
                              placeholder: "请输入菜单特殊编码",
                              width: _vm.width,
                              maxlength: 80,
                            },
                            model: {
                              value: _vm.form.specialNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "specialNo", $$v)
                              },
                              expression: "form.specialNo",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "版本号",
                        rules: [
                          {
                            required: true,
                            message: "请输入版本号",
                            trigger: "change",
                          },
                        ],
                        prop: "createVersion",
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: {
                          type: "textareaHor",
                          placeholder: "请输入版本号",
                          width: _vm.width,
                        },
                        model: {
                          value: _vm.form.createVersion,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "createVersion", $$v)
                          },
                          expression: "form.createVersion",
                        },
                      }),
                      _c("span", { staticClass: "tips" }, [
                        _vm._v("注：如 1.25"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="MenuForm">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      @update="update"
    >
      <col2-detail>
        <col2-block>

          <el-form-item label="菜单名称" :rules="[{ required: true, message: '请输入菜单名称', trigger: 'change' }]" prop="menuName">
            <v-input type="textareaHor" v-model="form.menuName" placeholder="请输入菜单名称" :maxlength="10" :width="width" />
          </el-form-item>
          <el-form-item v-if="idVisible" label="菜单ID">
            <span v-text="form.id" />
          </el-form-item>
          <el-form-item v-else label="菜单ID" :rules="[{ required: true, message: '请输入菜单ID', trigger: 'blur' }]" prop="id">
            <v-input-number v-model="form.id" placeholder="请输入菜单ID" controls controls-position="right" :max="99999999999" :width="width" />
            <v-button class="tips" type="text" text="点击获取" @click="queryNewMenuId" />
          </el-form-item>
          <el-form-item v-show="isAdd && showPlatformType" label="使用终端" prop="menuScope" :rules="[{ required: true, message: '请选择使用终端', trigger: 'change' }]" >
            <v-select v-model="form.menuScope" :options="menuScopeOps" :width="width" />
            <span class="tips">注：父子层级的所属终端，请尽量保持一致，否则将造成菜单无法正常显示的情况</span>
          </el-form-item>
          <el-form-item v-show="!isAdd && showPlatformType" label="使用终端">
            <span>{{menuScopeMap[form.menuScope]}}</span>
          </el-form-item>

          <el-form-item v-if="menuLevelVisible" label="层级">
            <span v-text="menuLevelMap[form.menuLevel]" />
          </el-form-item>
          <el-form-item v-else label="层级" :rules="[{ required: true, message: '请选择层级', trigger: 'change' }]" prop="menuLevel">
            <v-select v-model="form.menuLevel" :options="menuLevelOps" :width="width" @change="menuLevelChange" />
          </el-form-item>

          <el-form-item v-if="iconSize" label="图标" :rules="[{ required: true, message: '请上传图标', trigger: 'change' }]" prop="iconUrl">
            <v-uploader :action="uploadURL" :imgUrls.sync="form.iconUrl" />
              <span class="tips">{{ '注：上传' + iconSize + '显示最佳' }}</span>
          </el-form-item>
          <el-form-item v-if="iconVisible" label="图标">
            <v-uploader :action="uploadURL" :imgUrls.sync="form.iconUrl" />
              <span class="tips">注：上传22*22显示最佳</span>
          </el-form-item>
          <el-form-item v-if="typeVisible && typeNameVisible" label="所属平台">
            <span v-text="subPlatformName" />
          </el-form-item>
          <el-form-item v-if="typeVisible && !typeNameVisible" label="所属平台" :rules="[{ required: true, message: '请选择所属平台', trigger: 'change' }]" prop="type">
            <v-select2 v-model="form.type" key="select2Type" v-bind="typeParams" placeholder="查询所属平台" :width="width" />
          </el-form-item>
          <el-form-item v-if="parentMenuVisible && parentMenuNameVisible" label="上级菜单">
              <span v-text="parentName" />
          </el-form-item>
          <el-form-item v-if="parentMenuVisible && !parentMenuNameVisible" label="上级菜单" :rules="[{ required: true, message: '请选择上级菜单', trigger: 'change' }]" prop="parentId">
            <v-select2
              ref="parentMenu"
              v-model="form.parentId"
              key="select2ParentId"
              v-bind="parentMenuParams"
              :subjoin="parentMenuSubjoin"
              placeholder="查询上级菜单"
              :width="width"
              @onChange="parentMenuChange" />
          </el-form-item>

          <el-form-item v-if="menuTypeVisible" label="类型">
            <span v-text="menuTypeMap[form.menuType]" />
          </el-form-item>
          <el-form-item v-else label="类型" :rules="[{ required: true, message: '请选择类型', trigger: 'change' }]" prop="menuType">
            <v-select v-model="form.menuType" :options="menuTypeOps" :width="width" @change="menuTypeChange" />
          </el-form-item>

          <el-form-item v-if="menuUrlVisible" label="菜单URL" :rules="[{ required: true, message: '请输入菜单URL', trigger: 'change' }]" prop="menuUrl">
            <v-input type="textareaHor" v-model="form.menuUrl" placeholder="请输入菜单URL" :maxlength="5000" :width="lwidth" />
            <span v-if="form.authType !== 0"  class="tips">注：为保障平台数据安全，请配置“HTTPS”协议的URL</span>
          </el-form-item>
          <el-form-item v-if="visible" label="打开方式" :rules="[{ required: true, message: '请选择打开方式', trigger: 'change' }]" prop="urlJumpType">
            <v-select v-model="form.urlJumpType" :options="urlJumpTypeOps" :width="width" />
          </el-form-item>
          <el-form-item v-if="visible" label="授权方式" :rules="[{ required: true, message: '请选择授权方式', trigger: 'change' }]" prop="authType">
            <v-select v-model="form.authType" :options="authTypeOps" :width="width" />
          </el-form-item>
          <el-form-item v-if="form.menuUrl === 'sharePage'" label="表单URL" >
            <v-input type="textareaHor" v-model="form.reportUrl" placeholder="请输入表单文档URL" :maxlength="5000" :width="lwidth" />
          </el-form-item>
          <el-form-item v-if="showHelpUrl" label="帮助文档URL" >
            <v-input type="textareaHor" v-model="form.helpUrl" placeholder="请输入帮助文档URL" :maxlength="5000" :width="lwidth" />
          </el-form-item>

          <el-form-item v-if="roleTypeVisible" label="按钮类型" :rules="[{ required: true, message: '请选择按钮类型', trigger: 'change' }]" prop="roleType">
            <v-select v-model="form.roleType" :options="roleTypeOps" :width="width" />
            <span class="tips">影响角色管理权限的显示位置（列）</span>
          </el-form-item>
          <el-form-item v-if="menuCodeVisible" label="按钮code" :rules="[{ required: true, message: '请输入按钮code', trigger: 'change' }]" prop="menuCode">
            <v-input type="textareaHor" v-model="form.menuCode" placeholder="请输入按钮code" :maxlength="20" :width="width" />
            <span class="tips">影响按钮实际操作效果</span>
          </el-form-item>
          <el-form-item v-if="allowUserTypeVisible" label="角色归属" :rules="[{ required: true, message: '请选择角色归属', trigger: 'change' }]" prop="allowUserType">
            <checkbox-plus type="default" :options="allowUserTypeOps" :value.sync="form.allowUserType" mode="checkbox-plus" />
          </el-form-item>

          <el-form-item label="菜单排序" :rules="[{ required: true, message: '请输入菜单排序', trigger: 'blur' }]" prop="menuSort">
            <v-input-number v-model="form.menuSort" placeholder="请输入菜单排序" controls controls-position="right" :max="99" :width="width" />
            <span class="tips">建议设置为1、2</span>
          </el-form-item>
          <el-form-item v-if="showSpecialNo && !editSpecialNo" label="菜单特殊编码">
            <span v-text="detailItem.specialNo" />
          </el-form-item>
          <el-form-item
            v-if="showSpecialNo && editSpecialNo"
            label="菜单特殊编码"
            :prop="needSpecialNo ? `specialNo` : undefined"
            :rules="needSpecialNo ? [{ required: true, message: '请输入菜单特殊编码', trigger: 'blur' }] : undefined">
            <v-input type="textareaHor" v-model="form.specialNo" placeholder="请输入菜单特殊编码" :width="width" :maxlength="80"/>
          </el-form-item>
          <el-form-item label="版本号" :rules="[{ required: true, message: '请输入版本号', trigger: 'change' }]" prop="createVersion">
            <v-input type="textareaHor" v-model="form.createVersion" placeholder="请输入版本号" :width="width" />
            <span class="tips">注：如 1.25</span>
          </el-form-item>

        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import { queryNewMenuIdURL, createURL, editURL, getQueryDetailURL, uploadURL, queryMenuListURL } from './api'
import { setMenuLevelOps, menuLevelMap, setMenuTypeOps, menuTypeMap, setUrlJumpTypeOps, setAuthTypeOps, setRoleTypeOps, setAllowUserTypeOps, menuScopeOps, menuScopeMap } from './map'
import { vUploader } from 'components/control'
import { Col2Detail, Col2Block, CheckboxPlus } from 'components/bussiness'

export default {
  name: 'MenuForm',
  components: {
    vUploader,
    Col2Detail,
    Col2Block,
    CheckboxPlus
  },
  data () {
    return {
      width: 200,
      lwidth: 400,
      uploadURL,
      menuLevelMap,
      menuTypeMap,
      idVisible: false,
      menuLevelVisible: false,
      menuTypeVisible: false,
      typeNameVisible: false,
      parentMenuNameVisible: false,
      specialNoNameVisible: false,
      subPlatformName: '',
      parentName: '',
      // menuLevelOps: setMenuLevelOps(),
      menuTypeOps: [],
      urlJumpTypeOps: setUrlJumpTypeOps(),
      authTypeOps: setAuthTypeOps(),
      roleTypeOps: setRoleTypeOps(),
      allowUserTypeOps: setAllowUserTypeOps(),
      menuScopeOps: menuScopeOps(),
      menuScopeMap,
      typeParams: {
        searchUrl: queryMenuListURL,
        request: {
          text: 'menuName',
          value: 'id'
        },
        response: {
          text: 'menuName',
          value: 'id'
        },
        subjoin: {
          menuLevel: '-1'
        }
      },
      parentMenuParams: {
        searchUrl: queryMenuListURL,
        request: {
          text: 'menuName',
          value: 'id'
        },
        response: {
          text: 'menuName',
          value: 'id'
        }
      },
      form: {
        id: undefined,
        menuName: '',
        menuLevel: undefined,
        menuType: undefined,
        iconUrl: '',
        urlJumpType: undefined,
        authType: undefined,
        type: undefined,
        parentId: undefined,
        menuSort: undefined,
        allowUserType: [],
        menuUrl: '',
        menuCode: '',
        roleType: undefined,
        specialNo: '',
        createVersion: '',
        menuScope: undefined,
        helpUrl: '',
        reportUrl: '',
      },
      submitConfig: {
        submitMethod: '',
        queryUrl: '',
        submitUrl: ''
      },
      detailItem: {},
      parentMenuItem: undefined,
      isAdd: true,
      userType: ''
    }
  },
  mounted () {
    this.userType =this.$store.state.userInfo.userType
    const { id, parentId } = this.$route.query
    if (id !== undefined) {
      this.isAdd = false
      this.$setBreadcrumb('编辑菜单')
      this.submitConfig.submitMethod = 'PUT'
      this.submitConfig.submitUrl = editURL
      this.submitConfig.queryUrl = getQueryDetailURL(id)
      this.$refs.formPanel.getData()
    } else {
      this.$setBreadcrumb('新增菜单')
      this.submitConfig.submitMethod = 'POST'
      this.submitConfig.submitUrl = createURL
      if (parentId !== undefined) {
        this.submitConfig.queryUrl = getQueryDetailURL(parentId)
        this.$refs.formPanel.getData()
      }
      this.form.allowUserType = ['100', '101', '102', '106']
    }
    this.form.menuLevel = this.menuLevelOps[0].value
    this.form.urlJumpType = this.urlJumpTypeOps[0].value
    this.form.authType = this.authTypeOps[0].value
    this.form.roleType = this.roleTypeOps[0].value
    this.form.menuScope = this.menuScopeOps[0].value
  },
  watch: {
    // 原生页面的特殊编号默认和菜单url一致，按钮取父菜单的菜单url + 按钮code
    parentMenuItem (newVal) {
      if (this.form.menuType === 3) {
        let canChange = false
        if (this.isAdd) {
          canChange = true
        } else {
          canChange = !this.detailItem.specialNo || this.detailItem.specialNo.length === 0
        }
        if (canChange) {
          // 按钮取父菜单的菜单url + 按钮code
          let menuUrl = newVal ? newVal.menuUrl : ''
          this.form.specialNo = `${menuUrl || ''}${this.menuCode || ''}`
        }
      }
    },

    'form.menuUrl' (newVal) {
      // 原生页面的特殊编号默认和菜单url一致
      if (this.editSpecialNo && this.form.menuType === 2) {
        // 代表是必填，且是页面
        this.form.specialNo = `${newVal || ''}`
      }
    },

    'form.menuCode' (newVal) {
      if (this.editSpecialNo && this.form.menuType === 3) {
        // 代表是必填，且是按钮
        let menuUrl = this.parentMenuItem ? this.parentMenuItem.menuUrl : ''
        this.form.specialNo = `${menuUrl || ''}${newVal || ''}`
      }
    }
  },
  computed: {
    menuLevelOps() {
      if(this.$store.state.userInfo.userType === '102'){
        return setMenuLevelOps().splice(0,4)
      }else{
        return setMenuLevelOps()
      }
    },
    parentMenuSubjoin () {
      return {
        topParentId: this.form.type,
        menuType: this.form.menuType === 3 ? 2 : 1,
        menuLevel: this.form.menuLevel === undefined ? undefined : this.form.menuLevel - 1
      }
    },
    iconSize () {
      const menuLevel = this.form.menuLevel
      if (menuLevel === -1) {
        return '110*110'
      } else if (menuLevel === 0) {
        return '24*24'
      } else if (menuLevel === 1) {
        return '22*22'
      } else {
        return ''
      }
    },
    iconVisible () {
      const menuLevel = this.form.menuLevel
      const menuType = this.form.menuType
      return (menuLevel === 2 || menuLevel === 3) && menuType === 2
    },
    typeVisible () {
      return this.form.menuLevel !== -1
    },
    parentMenuVisible () {
      return this.form.menuLevel !== -1
    },
    visible () {
      return this.form.menuLevel === -1 || this.form.menuType === 2
    },
    allowUserTypeVisible () {
      return this.form.menuType === 3
    },
    menuUrlVisible () {
      const menuLevel = this.form.menuLevel
      const menuType = this.form.menuType
      const authType = this.form.authType
      return menuType === 2 || (menuLevel === -1 && (authType !== 0))
    },
    menuCodeVisible () {
      return this.form.menuType === 3
    },
    roleTypeVisible () {
      return this.form.menuType === 3
    },

    showPlatformType () {
      // 按钮类型，不显示使用终端
      return this.form.menuType !== 3
    },
    showHelpUrl () {
      // 页面类型显示帮助文档url
      return this.form.menuType === 2
    },
    // 特殊编码的显示
    showSpecialNo () {
      let show = false
      // menuType 1文件夹 2页面 3按钮
      // authType 0原生 1非授权 2需授权
      // 原生页面和原生页面下的按钮需必填，文件夹非必填；其他类型，需隐藏；
      if (this.form.menuType === 1) {
        show = true
      } else if (this.form.menuType === 2) {
        // 原生页面
        show = this.form.authType === 0
      } else if (this.form.menuType === 3) {
        if (this.isAdd) {
          // 代表是新增页面
          if (this.parentMenuItem) {
          // 原生页面下的按钮
            show = this.parentMenuItem.authType === 0
          }
        } else {
          // 编辑页面
          if (this.detailItem) {
            show = this.detailItem.specialNo && this.detailItem.specialNo.length > 0
            // 编辑状态下,从其他类型修改了为了按钮类型，且之前没有specialNo
            if (this.parentMenuItem) {
              // 原生页面下的按钮
              show = !show && this.parentMenuItem.authType === 0
            }
          }
        }
      }

      return show
    },

    // 特殊编码的必填
    needSpecialNo () {
      // menuType 1文件夹 2页面 3按钮
      // authType 0原生 1非授权 2需授权
      let show = false
      // 原生页面和原生页面下的按钮需必填，文件夹非必填；其他类型，需隐藏；
      if (this.form.menuType === 2) {
        // 原生页面
        show = this.form.authType === 0
      } else if (this.form.menuType === 3) {
        if (this.parentMenuItem) {
          // 原生页面下的按钮
          show = this.parentMenuItem.authType === 0
        }
      }
      return show
    },

    // 特殊编号 是否可编辑
    editSpecialNo () {
      let edit = false
      if (!this.isAdd) {
        // 代表是编辑
        if (this.showSpecialNo) {
          // 且之前没有值
          edit = !this.detailItem.specialNo || this.detailItem.specialNo.length === 0
          if (edit && this.form.menuType === 2) {
            // 没有值且是页面，需要赋值默认值
            this.$set(this.form, 'specialNo', this.form.menuUrl)
          }
        }
      } else {
        edit = true
      }
      return edit
    }
  },
  methods: {
    async queryNewMenuId () {
      const { status, data } = await this.$axios.get(queryNewMenuIdURL)
      if (status === 100) {
        this.form.id = data
        this.$refs.formPanel.validateField('id')
      }
    },
    initParams () {
      // 打开方式, 设置默认值
      if (this.form.urlJumpType === -1) {
        this.form.urlJumpType = this.urlJumpTypeOps[0].value
      }
      // 授权方式, 设置默认值
      if (this.form.authType === -1) {
        this.form.authType = this.authTypeOps[0].value
      }
    },
    menuLevelChange (val) {
      // 类型
      const menuTypeOps = setMenuTypeOps()
      if ([-1, 0].includes(val)) { // 顶级菜单/一级菜单, 类型选项只能为文件夹
        menuTypeOps.splice(1, 2)
      } else if ([1].includes(val)) { // 二级菜单, 类型选项只能为文件夹、页面
        if(this.userType==='102'){
          menuTypeOps.splice(1, 2)
        } else {
          menuTypeOps.splice(2, 1)
        }       
      } else if ([3].includes(val)) { // 四级菜单, 类型选项只能为页面、按钮
        menuTypeOps.splice(0, 1)
      } else if ([4].includes(val)) { // 五级菜单, 类型选项只能为按钮
        menuTypeOps.splice(0, 2)
      } else if([2].includes(val)) {  //三级菜单，102只有文件夹
        if(this.userType==='102'){
          menuTypeOps.splice(1, 2)
        } 
      }
      this.menuTypeOps = menuTypeOps
      this.form.menuType = menuTypeOps[0].value
      if ([-1].includes(val)) {
        this.initParams()
      }
      this.$refs.formPanel && this.$refs.formPanel.clearValidate()
    },
    menuTypeChange (val) {
      if ([2].includes(val)) {
        this.initParams()
      }
      this.$refs.formPanel && this.$refs.formPanel.clearValidate()
    },
    update (data) {
      const { parentId } = this.$route.query
      this.detailItem = data || {}
      if (parentId !== undefined) {
        // 类型
        const menuLevel = data['menuLevel'] + 1
        this.form['menuLevel'] = menuLevel
        this.menuLevelChange(menuLevel)
        const menuTypeOps = []
        let ops = []
        if (data['menuType'] === 1) {
          ops = [1, 2]
        } else if (data['menuType'] === 2) {
          ops = [3]
        }
        this.menuTypeOps.forEach(item => {
          if (ops.includes(item.value)) {
            menuTypeOps.push(item)
          }
        })
        this.menuTypeOps = menuTypeOps
        this.form.menuType = menuTypeOps[0].value
        this.menuLevelVisible = true
        // 所属平台
        if (data['menuLevel'] === -1) {
          this.form['type'] = data['id']
          this.subPlatformName = data.menuName
        } else {
          this.form['type'] = data['type']
          this.subPlatformName = data.subPlatformName
        }
        this.typeNameVisible = true
        // 上级菜单
        this.form['parentId'] = data['id']
        this.parentName = data.menuName
        this.parentMenuNameVisible = true
        // 添加子菜单的 上级菜单数据
        this.parentMenuItem = {
          authType: data.authType,
          menuUrl: data.menuUrl,
          menuName: data.menuName
        }
      } else {
        if (data['roleType'] === 0) {
          data['roleType'] = undefined
        }
        if (typeof data['allowUserType'] === 'string') {
          data['allowUserType'] = data['allowUserType'].split(',')
        }
        Object.keys(this.form).forEach(key => {
          if (data[key] !== undefined) {
            if (key === 'menuType') {
              this.$nextTick(() => {
                this.form[key] = data[key]
              })
            } else {
              this.form[key] = data[key]
            }
          }
        })
        this.idVisible = true
        this.specialNoNameVisible = true
        if ([-1].includes(this.form.menuLevel)) {
          this.menuLevelVisible = true
          this.menuTypeVisible = true
        } else if ([0].includes(this.form.menuLevel)) {
          this.menuTypeVisible = true
        }
        if(this.userType==='102'){
          this.menuLevelVisible = true
          this.menuTypeVisible = true
        }
      }
    },
    submitBefore (data) {
      if (!this.typeVisible) {
        delete data.type
      }
      // if (!this.parentMenuVisible) {
      //   delete data.parentId
      // }
      if (!this.visible) {
        delete data.urlJumpType
        delete data.authType
      }
      if (!this.allowUserTypeVisible) {
        delete data.allowUserType
      } else {
        data.allowUserType = data.allowUserType.join(',')
      }
      if (!this.menuUrlVisible) {
        delete data.menuUrl
      }
      if (!this.menuCodeVisible) {
        delete data.menuCode
      }
      if (!this.roleTypeVisible) {
        delete data.roleType
      }

      if (!this.showSpecialNo) {
      //  和驰旭商量，不显示，就给后台一个空字符串
        data.specialNo = ''
      }

      // 操作日志的数据对象参数
      let dataObject = `${data.menuName}`
      if (this.parentName) {
        dataObject = `${data.menuName}-${this.parentName}`
      } else if (this.$refs.parentMenu && this.$refs.parentMenu.getBackTextValue()) {
        dataObject = `${data.menuName}-${this.$refs.parentMenu.getBackTextValue()}`
      }
      data.dataObject = dataObject

      return true
    },

    parentMenuChange (value, echo) {
      if (!echo) {
        this.parentMenuItem = value
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.MenuForm {
  .tips {
    margin-left: 20px;
  }
}
</style>
